import React from 'react'
import '../assets/css/Footer.css'
export default function Footer() {
  return (
    <div className='footer'>
       <div className='footer-flex'>
        <div className='footer-container'>
        <div className='actualizat'>
         </div>
            <div className='copyright'>
              <span>© Roman Tarita tel: </span> 
                <a href='tel:069767760'>069 767 760</a>
                 <span> by </span>
                 <a href="https://prolex.md/" target="_blank" rel='noreferrer'>Pro-Lex</a> 
                 <p>actualizat la 02.01.2024, ora 15:00</p> 
         </div>
        </div>
       </div>
    </div>
  )
}
