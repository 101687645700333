import React from 'react'
import MySelect from '../UI/select/MySelect'

export default function SelectWrapper({label,onChange,value,options,name,isRequired,index}) {
  return (
    <div className='input-wrapper'>
    <label htmlFor={name}>
      {label}
     </label>
     <MySelect 
     options = {options}
     onChange={onChange}
     id={name}
     value={value}
     label={label}
     required={isRequired}
     index= {index}
     />
    </div>
  )
}
