import React, { useContext, useEffect, useRef, useState } from 'react';
import SelectWrapper from './SelectWrapper';
import NumericInput from './NumericInput';
import {requestTypes, insolvencyTypes } from '../assets/data/option';
import { FormData } from '../context/FormContextData';
import MyButton from '../UI/button/MyButton';
import calcService from '../service/CalcService';
import ToggleButton from './ToggleButton';

export default function CalculatorForm({ onResultReceived }) {
  const { formDataList, addFormData, removeFormData, setInstanceType, setActionType, setActionSum, setRequestType, setInsolvencyType ,instanceType,personType,setPersonType} = useContext(FormData);
 
  const [filteredRequestTypes, setFilteredRequestTypes] = useState(requestTypes);

  const handlePersonTypeChange = (isChecked) => {
    setPersonType(isChecked);

    // Conditionally update options based on personType
    const optionsToHide = ['Desfacerea căsătoriei', 'Stabilirea domiciliului copilului minor'];

    const updatedRequestTypes = !isChecked
      ? requestTypes.filter((option) => !optionsToHide.includes(option.label))
      : requestTypes;

    // Update the options in the component state
    setFilteredRequestTypes(updatedRequestTypes);
  };
 
 
 
 
  const handleSubmit = (e) => {
    e.preventDefault();

    if(!(personType || personType === false)){
      alert("Alegeti tip persoana!");
      return;
    }

    if(!instanceType){
      alert("Alegeti tip instanta!");
      return;
    }
    if(!formDataList[0].actionType){
      alert("Alegeti tip actiune!");
      return;
    }
    getResult();
  };
  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (!hasEffectRun.current) {
      addFormData();
      hasEffectRun.current = true;
    }
  }, [addFormData]);
  const getResult = async () => {
    try {
      const body ={
        inputs:formDataList.map((formData) => ({
        actionType: formData.actionType ? formData.actionType : null,
        actionSum: formData.actionSum,
        instanceType: instanceType,
        personType: personType ? "Persoana fizica" : "Persoana juridica",
        requestType: formData.actionType && formData.actionType === "Insolvabilitate" ? formData.insolvencyType.value : formData.actionType === "Patrimonial" ? null :formData.requestType.value
      }))} 
      
      const data = await calcService.getStateFeeValue(body);
      onResultReceived(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddForm = (e) => {
    e.preventDefault();
    addFormData();
  }
  const handleRemoveFormData = (index) => {
    // Call the removeFormData function from the context
    removeFormData(index);
  };
  return (
    <div className='form-container'>
    <form onSubmit={handleSubmit}>
    <div>
  <label htmlFor='personType'>Tip Persoană</label>
<ToggleButton 
          labelLeft='Fizică'
          labelRight='Juridică'
          isChecked={personType}
          id="personType"
          clickLeft={() => handlePersonTypeChange(true)}
          clickRight={() => handlePersonTypeChange(false)}
          />
</div>
     <div className={`${!(personType || personType === false) ? 'disabled' : ''}`}>
     <label htmlFor="instanceType">Tip instanță</label>
     <div className='buttons-wrapper'>
      <MyButton 
      className={`${instanceType === 'Prima instanta' ? 'checked' : ''}`}
      body="Prima"
      type="button"
      id="instanceType"
      onClick={() => setInstanceType('Prima instanta')}
      disabled={!(personType || personType === false)}
      />
      <MyButton 
      className={`${instanceType === 'Apel' ? 'checked' : ''}`}
      body="Apel"
      type="button"
      id="instanceType"
      onClick={() => setInstanceType('Apel')}
      disabled={!(personType || personType === false)}
      />
      <MyButton 
      className={`${instanceType === 'Recurs' ? 'checked' : ''}`}
      body="Recurs"
      type="button"
      id="instanceType"
      onClick={() => setInstanceType('Recurs')}
      disabled={!(personType || personType === false)}
      />
      <MyButton 
      className={`${instanceType === 'Revizuire' ? 'checked' : ''}`}
      body="Revizuire"
      type="button"
      id="instanceType"
      onClick={() => setInstanceType('Revizuire')}
      disabled={!(personType || personType === false)}
      />
     </div>
     </div>
      {formDataList.map((formData, index) => (
        <div key={index} className={`form-row ${instanceType ? '' : ' disabled'}`}>
         <div className='row-title'>
         <div className='title'><h3>Taxa de stat {index + 1}</h3></div>
<div className='title-buttons-wrapper'>
<MyButton 
    key={index}
    onClick={() => handleRemoveFormData(index)}
    body="Sterge taxa"
    type="button"
    className={`delete-button ${(formDataList.length <= 1) ? ' hide' : ''}`}
    disabled={ !instanceType || (formDataList.length <= 1)} 
    />
    <MyButton 
    className='add-button'
    onClick={handleAddForm}
    body="Adaugă taxă "
    type="button"
    disabled={instanceType ? false : true}
    />
</div>
         </div>
          {(
            <div>
              <label htmlFor="instanceType">Tip acțiune</label>
             <div className='buttons-wrapper'>
             <MyButton 
              className={`${formData.actionType === 'Patrimonial' ? 'checked' : ''}`}
              body="Patrimonial"
              type="button"
              id={`ActionType_${index}`}
              onClick={() => setActionType(index,'Patrimonial')}
              required
              disabled={instanceType ? false : true}
                   />
              <MyButton 
              className={`${formData.actionType === 'Nepatrimonial' ? 'checked' : ''}`}
              body="Nepatrimonial"
              type="button"
              id={`ActionType_${index}`}
              onClick={() => setActionType(index,'Nepatrimonial')}
              required
              disabled={instanceType ? false : true}
                   />
              <MyButton 
              className={`${formData.actionType === 'Insolvabilitate' ? 'checked' : ''}`}
              body="Insolvabilitate"
              type="button"
              id={`ActionType_${index}`}
              onClick={() => setActionType(index,'Insolvabilitate')}
              required
              disabled={instanceType ? false : true}
                   />
             </div>
            </div>
          )}
          {formData.actionType && formData.actionType === "Patrimonial" ? (
            <div>
              <div className="input-wrapper">
                <label htmlFor={`ActionSum_${index}`}>Sumă Actiune</label>
                <NumericInput
                  name={`ActionSum_${index}`}
                  value={formData.actionSum}
                  onChange={(e) => setActionSum(index,e.target.value)}
                  isRequired={true}
                />
              </div>
            </div>
          ) : formData.actionType && formData.actionType === "Nepatrimonial" ? (
            <div>
              <SelectWrapper
                options={filteredRequestTypes}
                onChange={setRequestType}
                name={`RequestType_${index}`}
                value={formData.requestType}
                label="Tip Cerere"
                isRequired={true}
                index={index}
              />
            </div>
          ) : formData.actionType && formData.actionType === "Insolvabilitate" && (
            <div>
              <SelectWrapper
                options={insolvencyTypes}
                onChange={setInsolvencyType}
                name={`InsolvencyType_${index}`}
                value={formData.insolvencyType}
                label="Tip cerere"
                isRequired={true}
                index={index}
              />
              {formData.insolvencyType && formData.insolvencyType.value === "Acțiunile cu caracter patrimonial depuse în cadrul procedurilor de insolvabilitate" && (
                <div>
                  <div className="input-wrapper">
                    <label htmlFor={`ActionSum_${index}`}>Sumă Actiune</label>
                    <NumericInput
                      name={`ActionSum_${index}`}
                      value={formData.actionSum}
                      onChange={(e) => setActionSum(index,e.target.value)}
                      isRequired={true}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <MyButton
      className="calc-button"
      id="calc-button"
      body="Calculeaza" />
    </form>
    </div>
  );
}
