import axios from 'axios';
const BASE_URL = "https://api.prolex.md/api/calculator";


const calcService = {

    getStateFeeValue: async (body) => {
        try{
            const response = await axios.post(`${BASE_URL}/stateFeeCalculate`, body, {
                withCredentials: true
            });
            return response.data;
        }catch(error){
            throw error;
        }
    },
    sendFeedback: async (message, calcDetails) => {
        try{
            const response = await axios.post(`${BASE_URL}/sendFeedback`,{
                message: message,
                calcDetails: calcDetails
            },{
                withCredentials: true});
                return response.data;
        }catch(error){
            throw error;
        }
    }
}

export default calcService