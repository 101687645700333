import React from 'react'
import classes from './MyButton.module.css'

export default function MyButton({body,className, ...props}) {
  return (
    <button className={`${classes.button28} ${className ? className : ''}`} {...props}>
        {body}
    </button>
  )
}
