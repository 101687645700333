import React from 'react';
import MyInput from '../UI/input/MyInput';

export default function NumericInput({ value, onChange, allowDecimals=true, ...props }) {
    const handleInputChange = (e) => {
        const regex = allowDecimals ? /^[0-9]*([.,][0-9]*)?$/ : /^[0-9]*$/;
        let inputValue = e.target.value;

        inputValue = inputValue.replace(',', '.');

        if (inputValue === '' || regex.test(inputValue)) {
            onChange({ ...e, target: { ...e.target, value: inputValue } });
        }
    }

    return (
        <MyInput 
            type="text" 
            value={value} 
            onChange={handleInputChange} 
            {...props} 
        />
    );
}
