import React from 'react'
import Select from 'react-select';
import classes from './MySelect.module.css'
export default function MySelect({options, name, value, onChange,index, ...props}) {
  const customStyles = {
    container: (base,state) =>({
      ...base,
      width:"100%"
    }),
    control: (base, state) => ({
      ...base,
      width: '100%',
      height: '45px',
      borderRadius: '5px',
      border: state.isFocused ? '2px solid grey' : '1.5px solid lightgrey',
      boxShadow: state.isFocused ? '0px 0px 20px -18px' : null,
      outline: 'none',
      transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      color: 'black',
      backgroundColor: 'white',
      ':hover': {
        border: '2px solid lightgrey',
        boxShadow: '0px 0px 20px -17px',
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused || state.isSelected ? 'lightgrey' : 'white',
      color: 'black',
      padding: '8px 12px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10 
  }),
  placeholder:(base)=>({
    ...base,
    fontSize: '14px',
  })
    
  };
  return (
    <Select
      value={value}
      onChange={(option) => {
        if (typeof index === 'number') {
          onChange(index, option || null);
        } else {
          onChange(option || null);
        }
      }}
      options={options}
      styles={customStyles}
      {...props}
    />
  );
}
