import React from 'react'
import '../assets/css/PayLinks.css'
export default function PayLinks() {
  return (
    <div className='pay-container'>
        <a href="https://mpay.gov.md/Order/Search/AAIJ1" className='card' target="_blank">
            <div className="text-container">
              <p>Taxa de stat pentru cererile de chemare in judecată (e-Dosar judiciar)</p>
              </div>
        </a>
        <a href="https://mpay.gov.md/Services/Service/AAIJ2" className='card' target="_blank">
            <div className="text-container">
                <p>Taxa de stat pentru cererile de chemare în judecată</p>
            </div>
        </a>
        <a href="https://mpay.gov.md/Services/Service/AAIJ4" className='card' target="_blank">
            <div className="text-container">
                <p>Taxa de stat pentru eliberarea copiilor hotărârilor judecătorești</p>
                </div>
        </a>
        <a href="https://mpay.gov.md/Services/Service/AAIJ5" className='card' target="_blank">
            <div className="text-container">
                <p>Taxa pentru serviciul de imprimare a copiilor de cereri depuse în instanța de judecată (e-Dosar judiciar)</p>
            </div>
        </a>
        <a href="https://mpay.gov.md/Services/Service/AAIJ3" className='card' target="_blank">
            <div className="text-container">
                <p>Amenzi aplicate de instanțele judecătorești</p>
            </div>
        </a>
    </div>
  )
}
