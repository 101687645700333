import Navbar from "./components/Navbar.jsx";
import './assets/css/App.css';
import './assets/css/Navbar.css';
import MainPage from "./components/MainPage.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div className="App">
      <Navbar />
      <MainPage />
      <Footer />
    </div>
  );
}

export default App;
