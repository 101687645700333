import React from 'react'
import classes from './MyInput.module.css'
export default function MyInput({name, type, isRequired, placeholder,value, onChange, label,disabled,onClick,ref,checked,className}) {
  if (type === 'checkbox') {
    return (
      <>
        <input
          id={name}
          className={classes.input}
          type={type}
          name={name}
          required={isRequired}
          placeholder={placeholder}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </>
    );
  } else {
    return (
      <input
        className={`${classes.input} ${className}`}
        type={type}
        id={name}
        name={name}
        required={isRequired}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
      />
    );
  }
}
