// ToggleButton.js
import React from 'react';
import '../assets/css/ToggleButton.css'; // Import a CSS file for styling
import MyButton from '../UI/button/MyButton';

const ToggleButton = ({labelLeft, labelRight, isChecked, clickLeft, clickRight, ...props }) => {
  return (
    <div className='toggle-button'>
      <MyButton 
      className={`left-side ${isChecked === true ? 'checked' : ''}`}
      body={labelLeft}
      onClick={clickLeft}
      type="button"
      {...props}
      />
      <MyButton 
      className={`right-side ${isChecked === false? 'checked' : ''}`}
      body={labelRight}
      onClick={clickRight}
      type="button"
      {...props}
      />
    </div>
  );
};

export default ToggleButton;
