import { useEffect, useState } from "react";
import popupStyles from "./custom-popup.module.css";
import PropTypes from "prop-types";
const CustomPopup = (props) => {
  const [show, setShow] = useState(false);
  const [contentClickable, setContentClickable] = useState(false);
  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    let isMounted = true;
  
    setShow(props.show);
  
    const timeoutId = setTimeout(() => {
      if (isMounted) {
        setContentClickable(true);
      }
    }, 500);
  
    return () => {
      isMounted = false; // Set isMounted to false on unmount
      clearTimeout(timeoutId);
    };
  }, [props.show]);
  return (
<div
  style={{
    display: show ? "block" : "none",
    opacity: show ? "1" : "0",
    pointerEvents: contentClickable ? "auto" : "none", // Enable pointer events when content is clickable
  }}
  className={popupStyles.overlay}
>
      <div className={popupStyles.popup}>
        <h2>{props.title}</h2>
        <span className={popupStyles.close} onClick={closeHandler}>
          &times;
        </span>
        <div
          className={`${popupStyles.content} ${contentClickable ? "" : popupStyles.disabled}`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

CustomPopup.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default CustomPopup;