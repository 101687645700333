import React from 'react'
import { NavLink } from 'react-router-dom';
import '../assets/css/Navbar.css'
import Logo from './Logo'

export default function Navbar() {
  const handleClick = () => {
    window.location.href = '/';
  }
  return (
    <nav>
      <div className='nav-title'>
        <Logo 
        onClick={handleClick}/>
      </div>
      <ul>
          <div className='menu-left'>
            <li><a href="https://calc.prolex.md/penalitate" className="navbar-link" target="_blank">Penalitate</a></li>
            <li><a href="https://calc.prolex.md/dobanda" className="navbar-link" target="_blank">Dobânda de întârziere</a></li>
            <li><a href="https://calc.prolex.md/inflatie" className="navbar-link" target="_blank">Calcul inflație</a></li>
            <li><a href="https://calc.prolex.md/imputatia" className="navbar-link" target="_blank">Imputația</a></li>
            <li><a href="https://calc.prolex.md/zile" className="navbar-link" target="_blank">Calcul zile</a></li>
          </div>
          </ul>
    </nav>
  )
}
