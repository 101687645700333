import React, { createContext, useState } from 'react';

const FormData = createContext();

const FormDataProvider = ({ children }) => {
  const [formDataList, setFormDataList] = useState([]);
  const [instanceType, setInstanceType] = useState(null);
  const [personType, setPersonType] = useState(null);
  const addFormData = (formData) => {
    const initializedFormData = {
      ...formData,
      actionType: null,
      actionSum: '',   
      personType: null,
      requestType: null, 
      insolvencyType: null,
    };

    setFormDataList((prevList) => [...prevList, initializedFormData]);
  };

  const removeFormData = (index) => {
    setFormDataList((prevList) => prevList.filter((_, i) => i !== index));
  };


  const setActionType = (index, value) => {
    setFormDataList((prevList) =>
      prevList.map((formData, i) =>
        i === index ? { ...formData, actionType: value } : formData
      )
    );
  };
  const setActionSum = (index, value) => {
    setFormDataList((prevList) =>
      prevList.map((formData, i) =>
        i === index ? { ...formData, actionSum: value } : formData
      )
    );
  };
  const setRequestType = (index, value) => {
    setFormDataList((prevList) =>
      prevList.map((formData, i) =>
        i === index ? { ...formData, requestType: value } : formData
      )
    );
  };
  const setInsolvencyType = (index, value) => {
    setFormDataList((prevList) =>
      prevList.map((formData, i) =>
        i === index ? { ...formData, insolvencyType: value } : formData
      )
    );
  };

  // Add similar functions for other properties

  return (
    <FormData.Provider value={{ formDataList, addFormData, removeFormData, setInstanceType, setActionType, setActionSum, setPersonType, setRequestType, setInsolvencyType, instanceType,personType}}>
      {children}
    </FormData.Provider>
  );
};

export { FormData, FormDataProvider };
